* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: grey;
}

.Sculpture {
    max-width: 1200px;
    margin: 50px auto;
    padding: 0 50px;
}

.sculpture__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.sculpture__cards .card {
    height: 400px;
    box-shadow: inset 0px 0px 2px 0px rgba(0,0,0,0.75);
}

.sculpture__cards .card:hover {
    box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.sculpture__cards .card img {
    width: 100%;
    height: 90%;
    object-fit: contain;
    object-position: center;

}

.sculpture__cards .card p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
}




@media screen and (max-width: 992px) {
    .sculpture__cards {
        grid-template-columns: repeat(2, 1fr);
    }

    .sculpture__cards .card {
        height: 500px;
        box-shadow: inset 0px 0px 2px 0px rgba(0,0,0,0.75);
    }



}

@media screen and (max-width: 768px) {
    .Sculpture {
        padding: 0px 10px;
    }

    .sculpture__cards {
        grid-template-columns: repeat(1, 1fr);
    }

    .sculpture__cards .card {
        height: 350px;
    }

    .sculpture__cards .card img {
        object-fit: contain;
    }

    .sculpture__cards .card p {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }
}
.Footer {
    position: relative;
    margin-top: 200px;
    width: 100%;
}

.Footer p {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 20px 0px;
    border-top: 1px solid black;
}
    .Contact {
        margin: 50px auto;
        max-width: 500px;
    }

    .up_contact {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        row-gap: 50px;
        margin: 50px 0px;
    }

    .up_contact div {
        width: 100%;
        padding: 10px 0px;
        border: 1px solid rgb(93, 93, 133);
    }

    .up_contact div p, .up_contact div a {
        padding: 0px 30px;
        font-size: 18px;

    }

    form {
        max-width: 500px;
        margin: 0 auto;
        padding: 20px;
        background-color: #f4f4f4;
    }
    
    label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
    }
    
    input[type="text"],
    input[type="email"],
    textarea {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid grey;
        margin-bottom: 15px;
    }
    
    textarea {
        height: 100px;
    }

    input[type="submit"] {
        background-color: #333;
        color: #fff;
        padding: 10px 20px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
    }
    
    input[type="submit"]:hover {
        background-color: #555;
    }
    
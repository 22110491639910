.About {
    margin: 50px auto;
}

.about-us {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4;
}

.about-us h2 {
    font-size: 32px;
    margin-bottom: 20px;
}

.about-us p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 15px;
}

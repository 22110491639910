.latest_cards .latest_card {
    height: 300px !important;
    display: flex;
    flex-direction: column;
}

.latest_cards .latest_card:hover {
    opacity: 0.9;
}

.latest_card img {
    object-fit: cover !important;
}
.Home {
margin: 50px 0px;
}

.home-container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 50px 10px;
    border: 1px solid rgba(37, 37, 37, 0.1);
}

.home-container:hover {
    background-color: rgba(172, 148, 148, 0.1);
}

.home-container h1 {
    font-size: 48px;
    margin-bottom: 30px;
}

.home-container p {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.home-container button {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 20px;
}

.home-container button:hover {
    background-color: #555;
}

.best__product {
    margin: 50px auto;
}

.best__product h1 {
    text-align: center;
}
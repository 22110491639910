header {
    padding: 20px;
    margin-top: 20px;
}

nav {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin: 0 auto;
}

.logo {
    justify-self: center;
    align-self: center;
    font-size: 40px;
    font-weight: 200;
    color: #a8a8a8;
}

nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
}

nav li {
    margin: 0 10px;
}

nav a {
    color:  grey;
    text-decoration: none;
}

.hamburger {
    display: none;
    cursor: pointer;
}

.menu {
    width: 800px;
    max-width: 1000px;
    margin: 0 auto;
    justify-content: space-evenly;
}


.menu li {
    margin: 0 10px;
    font-size: 16px;
    font-weight: 400;
    color: #a8a8a8;
}

.close {
    display: none;
}




@media (max-width: 768px) {

    nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .logo {
        align-self: flex-start;
        font-size: 30px;
    }

    .hamburger {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
    }


    .close {
        display: inline-block;
        position: absolute;
        top: 10px;
        right: 30px;
        cursor: pointer;
    }
    
    .close button {
        border: none;
        background-color: transparent;
        color: grey;
        cursor: pointer;
        font-size: 30px;
    }

    .menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: #111;
        flex-direction: column;
        text-align: center;
        transform: translateX(-100%);
        transition: all 0.3s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
    }

    .menu.show {
        transform: translateX(0);
    }

    .menu li {
        margin: 20px 0;
        font-size: 24px;
    }

    .hamburger span {
        display: block;
        width: 25px;
        height: 3px;
        background-color: grey;
        margin: 3px auto;
        transition: all 0.3s ease-in-out;
    }

    .hamburger.show span:nth-child(2) {
    opacity: 0;
    }

    .hamburger.show span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
    }

    .hamburger.show span:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
    }
}
.Product {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    margin: 50px auto;
}

.product__left {
    width: 50%;
    height: 600px;
    box-shadow: inset 0px 0px 2px 0px rgba(0,0,0,0.75);
}



.product__right {
    width: 50%;
    height: 600px;
    box-shadow: inset 0px 0px 2px 0px rgba(0,0,0,0.75);
}


.product__up {
    height: 500px;
    display: flex;
    flex-direction: column;
}

.product__up table {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.product__up table tr {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: 1px solid rgb(182, 174, 174);
}

.product__up table tr td {
    padding-left: 20px;
    font-size: 20px;
}

.product__down {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid black;
}

.div_slider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.div_slider img {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.image_slider{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btn_slider  {
    display: flex;
    align-items: center;
    height: 100px;
    gap: 20px;
}

.btn_slider button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100px;
    height: 30px;
    font-size: 26px;
}


@media screen and (max-width: 768px) {
    .Product {
        flex-direction: column;
    }

    .product__left, .product__right {
        width: 100%;
    }

    .product__left {
        height: 400px;
        box-shadow: inset 0px 0px 2px 0px rgba(0,0,0,0.75);
    }

    .div_slider img {
        width: 100%;
        height: 350px;
    }

    .btn_slider  {
        display: flex;
        align-items: center;
        height: 50px;
        gap: 20px;
    }
    
    .btn_slider button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 100px;
        height: 30px;
        font-size: 26px;
    }

}